import React from "react"

import Section from "elements/Section"
import Address from "elements/Address"
import FormInput from "elements/Form/FormInput"
import FormCheckbox from "elements/Form/FormCheckbox"
import HospitalSelect from "elements/Hospitals/HospitalSelect"
import { isClinicDeliveryAddressOptions } from "../utils/doctorEnrollmentSchema"
import Message from "elements/Message"

const ClinicInformationSection = ({ values, primaryClinic, setFieldValue }) => {
  const clinicAddressFieldNames = {
    streetAddress: `primaryClinic.streetAddress`,
    city: `primaryClinic.city`,
    province: `primaryClinic.province`,
  }
  return (
    <Section title="Clinic Information">
      <Message color="light">
        Please provide your hospital or clinic address so that we can deliver
        the HCP Kit to you. You will be notified once the kits are ready for
        delivery.
      </Message>
      <HospitalSelect
        name={`primaryClinic.name`}
        value={primaryClinic.name}
        isRequired
      />
      {primaryClinic?.name.value === "Other" && (
        <FormInput
          name={`primaryClinic.otherHospital`}
          label="Please indicate your hospital/clinic"
          placeholder="Philippine General Hospital"
          isRequired
        />
      )}
      <FormInput
        name={`primaryClinic.clinicHours`}
        label="Clinic Hours"
        isRequired
        placeholder="MWF 8am-12nn, TTh 1-3pm"
      />
      <FormInput
        name={`primaryClinic.clinicContactNumber`}
        label="Clinic Contact Number"
        type="tel"
        maxLength={11}
        placeholder="88881234"
        isRequired
        isNumeric
      />
      <Address
        fieldNames={clinicAddressFieldNames}
        values={primaryClinic}
        setFieldValue={setFieldValue}
        isRequired
        isNationwide
      />
      <FormCheckbox
        values={values?.isClinicDeliveryAddress}
        options={isClinicDeliveryAddressOptions}
        hideOptional
        name="isClinicDeliveryAddress"
      />
    </Section>
  )
}

export default ClinicInformationSection
