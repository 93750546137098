import * as Yup from "yup"
import { REQUIRED_MESSAGE } from "services/validations"

export const isClinicDeliveryAddressOptions = [
  "Your clinic address is also your delivery address",
]

export const contactThroughChoices = ["SMS", "Email", "Call"]

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required(REQUIRED_MESSAGE),
  email: Yup.string()
    .email("Please input a valid email")
    .required(REQUIRED_MESSAGE),
  prcLicenseNumber: Yup.string()
    .min(5, "Please follow this format: 0012345")
    .max(7, "Please follow this format: 0012345")
    .matches(/^[0-9]*$/, "Please follow this format: 0012345")
    .required(REQUIRED_MESSAGE),
  specialization: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherSpecialization: Yup.string().when("specialization", {
    is: specialization => specialization?.value === "Other",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  primaryClinic: Yup.object().shape({
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    city: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    province: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    name: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    otherHospital: Yup.string().when("name", {
      is: name => name?.value === "Other",
      then: Yup.string().required(REQUIRED_MESSAGE),
    }),
    clinicHours: Yup.string().required(REQUIRED_MESSAGE),
    clinicContactNumber: Yup.string().required(REQUIRED_MESSAGE),
  }),
  deliveryAddress: Yup.object().when("isClinicDeliveryAddress", {
    is: isClinicDeliveryAddress => isClinicDeliveryAddress?.length < 1,
    then: Yup.object().shape({
      streetAddress: Yup.string().required(REQUIRED_MESSAGE),
      city: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      province: Yup.object().shape({
        value: Yup.string()
          .required(REQUIRED_MESSAGE)
          .nullable(),
      }),
      addressType: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
  contactThrough: Yup.array().min(1, REQUIRED_MESSAGE),
})

export const specializationChoices = [
  {
    label: "Endocrinology",
    value: "Endocrinology",
  },
  {
    label: "Cardiology",
    value: "Cardiology",
  },
  {
    label: "Nephrology",
    value: "Nephrology",
  },
  {
    label: "Internal Medicine",
    value: "Internal Medicine",
  },
  {
    label: "Diabetology",
    value: "Diabetology",
  },
  {
    label: "Other (please specify)",
    value: "Other",
  },
]
