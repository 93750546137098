import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"

import Section from "elements/Section"

const PersonalInformationSection = ({ enrollmentData }) => {
  return (
    <Section title="Personal Information">
      <p className="has-text-weight-bold">
        {enrollmentData.firstName} {enrollmentData.lastName}
      </p>
      <p>
        <FontAwesomeIcon icon={faEnvelope} className="mr-1" />{" "}
        {enrollmentData.email}
      </p>
      <p className="mb-1">
        <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
        {enrollmentData.mobileNumber}
      </p>
      <p>PRC License Number: {enrollmentData.prcLicenseNumber}</p>
      <p>PTR License Number: {enrollmentData.ptrLicenseNumber}</p>
    </Section>
  )
}

export default PersonalInformationSection
