import React, { useEffect } from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"

import classNames from "classnames"
import Container from "../Layout/Container"
import EarlyExperienceProgram from "./EarlyExperienceProgram"
import LearnMore from "./LearnMore"
import { hasDoctorRole } from "../Auth/services/authUser"
import Layout from "../Layout/Layout"
import { ProgramName, Brand } from "../Elements/Brand"

import styles from "../Layout/layout.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd } from "@fortawesome/free-solid-svg-icons"
import { useBriefingGuide } from "./hooks/useBriefingGuide"

const Doctor = () => {
  const data = useStaticQuery(graphql`
    {
      banner: file(
        relativePath: { eq: "novo_nordisk/banner-image__transparent.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const banner = data.banner.childImageSharp.fluid
  const pdfLink = useBriefingGuide()

  useEffect(() => {
    if (!hasDoctorRole()?.active) navigate("/profile")
  }, [])

  return (
    <Layout>
      <section className="hero is-medium">
        <div
          className="hero-body"
          // style={{
          //   backgroundImage: `url(${banner.src})`,
          //   backgroundPosition: "cover",
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "cover",
          // }}
        >
          <Container desktop={10} fullhd={10} isCentered>
            <div className="columns">
              <div
                className={classNames("column", styles["bannerImageContainer"])}
              >
                <Img
                  fluid={banner}
                  alt="Ozempic Early Experience Program"
                  className="p-0"
                />
              </div>
              <div className="column">
                <h2 className="mt-0-mobile">
                  The <ProgramName />
                </h2>
                <h4 className="subtitle">
                  The <ProgramName /> gives patients early access to <Brand />.
                  Through the program, you can gain clinical experience with{" "}
                  <Brand /> in treating patients with type 2 diabetes.
                </h4>
                <h4 className="subtitle mt-1">
                  Each enrolled doctor can nominate any number of patients for
                  the program, but only the first five (5) valid enrollees per
                  doctor will be accepted. If capacity frees up, we may allow
                  more enrollments in the future and keep you posted.
                </h4>
                <Link
                  className={classNames(
                    "button is-primary is-medium mt-2",
                    styles["home__buttonIsFullwidth"]
                  )}
                  to="/doctor/#learn-more"
                >
                  Nominate Patients
                </Link>
                <div className="is-flex mt-2 is-align-items-center">
                  <span className="is-size-3 icon has-text-primary">
                    <FontAwesomeIcon icon={faUserMd} />
                  </span>
                  <span className="mx-1">
                    Download the digital welcome kit{" "}
                    <a target="_blank" rel="noreferrer noopener" href={pdfLink}>
                      here
                    </a>
                    .
                  </span>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <LearnMore />
        <EarlyExperienceProgram />
      </section>
    </Layout>
  )
}

export default Doctor
