import React, { Fragment, useContext } from "react"
import { Form, Formik } from "formik"
import classNames from "classnames"
import { Link } from "gatsby"

import Message from "elements/Message"
import ActionLinks from "./ActionLinks"
import FormInput from "elements/Form/FormInput"

import { AppContext } from "../../../../context/AppContext"
import { handleEmailLogin } from "../../../Auth/services/authActions"
import { initialValues, validationSchema } from "../utils/verifyPasswordSchema"
import { doctorEnrollment } from "../../../../context/AppReducer"

const DEFAULT_MESSAGE =
  "This email is already a registered MedGrocer account. Please sign in using these credentials."
const NEW_DOCTOR = "new"

const VerifyPassword = ({ loading, email, setLoading }) => {
  const { dispatch, state } = useContext(AppContext)

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const handleSuccessCallback = ({ values }) => {
    dispatch({
      type: doctorEnrollment.SAVE_DOCTOR_ENROLLMENT,
      payload: {
        ...state.doctorEnrollment,
        ...values,
        status: NEW_DOCTOR,
      },
    })
  }

  const handleSubmit = (values, { setErrors, resetForm }) => {
    setLoading(true)
    handleEmailLogin({
      values,
      setErrors,
      resetForm,
      callback: () => handleSuccessCallback({ values }),
      errorCallback: handleErrorCallback,
    })
  }

  return (
    <Fragment>
      <Formik
        initialValues={initialValues(email)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values }) => {
          const message = {
            content: errors?.login?.message || DEFAULT_MESSAGE,
            color: errors?.login?.message ? "warning" : "light",
          }
          return (
            <Form>
              {/* disable field only if the email it initially received is the one in its form state. otherwise, let user type in email */}
              <FormInput
                name="email"
                isRequired
                label="Email"
                placeholder="juan@mail.com"
                disabled={email === values.email}
              />
              <FormInput
                name="password"
                isRequired
                label="Password"
                placeholder="********"
                type="password"
              />
              <p className="is-size-6 pb-1 pt-0">
                <Link to="/forgot-password">Forgot password</Link>
              </p>
              <Message color={message?.color}>{message?.content}</Message>
              <button
                className={classNames("button is-primary is-fullwidth", {
                  "is-loading": loading,
                })}
                type="submit"
              >
                Sign in with your MedGrocer account
              </button>
              <ActionLinks />
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}

export default VerifyPassword
