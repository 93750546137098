import React from "react"

import Hero from "../Layout/Hero"
import Container from "layout/Container"
import ProgramContainer from "./ProgramContainer"

import earlyProgramJson from "../Layout/utils/earlyOzempicPrograms.json"
import useHomeImages from "../Layout/hooks/useHomeImage"
import { ProgramName } from "../Elements/Brand"

const EarlyExperienceProgram = () => {
  const data = useHomeImages()

  return (
    <Hero size="small" className={{ heroBody: "pt-0 pb-0" }}>
      <Container isCentered desktop={10} fullhd={10}>
        <h3 className="my-3 has-text-centered has-text-primary">
          Under the <ProgramName />, your patients can:
        </h3>
        <div className="columns is-centered mb-1">
          {earlyProgramJson.map(item => (
            <ProgramContainer
              description={item?.description}
              image={data[item?.imageName]?.childImageSharp?.fixed}
            />
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default EarlyExperienceProgram
