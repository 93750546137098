export const doctorEnrollmentZendeskTemplate = ({ enrollmentData }) => {
  let {
    firstName,
    lastName,
    middleInitial,
    email,
    mobileNumber,
    prcLicenseNumber,
    ptrLicenseNumber,
    primaryClinic,
    isClinicDeliveryAddress,
    deliveryAddress,
    consentToBeEngaged,
    specialization,
    otherSpecialization,
    contactThrough,
  } = enrollmentData

  let {
    name,
    streetAddress,
    city,
    province,
    clinicHours,
    clinicContactNumber,
    otherHospital,
  } = primaryClinic

  let hospitalName = name?.value === "Other" ? otherHospital : name?.value
  const HOSPITAL_ADDRESS_TYPE = "Hospital"

  let currentSpecialization =
    specialization?.value === "Other"
      ? otherSpecialization
      : specialization?.value
  let contactThroughString =
    contactThrough.length > 0 ? contactThrough.join(", ") : "N/A"

  if (isClinicDeliveryAddress?.length > 0)
    deliveryAddress = { ...primaryClinic, addressType: HOSPITAL_ADDRESS_TYPE }

  return `Personal Details\nFirst Name: ${firstName}\nLast Name: ${lastName}\nMiddle Initial: ${middleInitial ||
    "N/A"}\nEmail: ${email}\nMobile Number: ${mobileNumber}\nPRC License Number: ${prcLicenseNumber}\nPTR License Number: ${ptrLicenseNumber ||
    "N/A"}\nConsent To Be Engaged: ${
    consentToBeEngaged.length > 0 ? "YES" : "NO"
  }\nSpecialization: ${currentSpecialization}\nI want to be contacted through: ${contactThroughString}\n---\nPrimary Clinic\nHospital/Clinic: ${hospitalName}\nClinic Hours: ${clinicHours ||
    "N/A"}\nClinic Contact Number: ${clinicContactNumber}\nStreet Address: ${streetAddress}\nCity: ${
    city?.value
  }\nProvince: ${province?.value}\n---\nDelivery Address\nAddress Type: ${
    deliveryAddress?.addressType
  }\nStreet Address: ${deliveryAddress?.streetAddress}\nCity: ${
    deliveryAddress?.city?.value
  }\nProvince: ${deliveryAddress?.province?.value}`
}
