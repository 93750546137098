import React from "react"

import Section from "elements/Section"
import Address from "elements/Address"

const DeliveryDetailsSection = ({ values, setFieldValue }) => {
  const deliveryAddressFieldNames = {
    addressType: `deliveryAddress.addressType`,
    streetAddress: `deliveryAddress.streetAddress`,
    city: `deliveryAddress.city`,
    province: `deliveryAddress.province`,
  }
  if (values?.isClinicDeliveryAddress?.length > 0) return null
  return (
    <Section title="Delivery Details">
      <Address
        fieldNames={deliveryAddressFieldNames}
        values={values.deliveryAddress}
        setFieldValue={setFieldValue}
        isRequired
        isNationwide
      />
    </Section>
  )
}

export default DeliveryDetailsSection
