import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBuilding,
  faHome,
  faMapMarkerAlt,
  faPhone,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons"

import Section from "elements/Section"

const ClinicInformationSection = ({ enrollmentData }) => {
  const {
    primaryClinic,
    deliveryAddress,
    isClinicDeliveryAddress,
  } = enrollmentData
  const clinicName =
    primaryClinic?.name?.value === "Other"
      ? primaryClinic?.otherHospital
      : primaryClinic?.name?.value
  const deliveryAddressIcon =
    deliveryAddress?.addressType === "Home" ? faHome : faBuilding

  return (
    <Section title="Clinic Information">
      <p className="has-text-weight-bold">{clinicName}</p>
      <p>
        <FontAwesomeIcon icon={faUserClock} className="mr-1" />{" "}
        {primaryClinic?.clinicHours}
      </p>
      <p>
        <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
        {primaryClinic?.clinicContactNumber}
      </p>
      <p>
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1" />{" "}
        {primaryClinic?.streetAddress}, {primaryClinic?.city?.value},{" "}
        {primaryClinic?.province?.value}
      </p>
      {isClinicDeliveryAddress?.length < 1 ? (
        <Fragment>
          <p className="has-text-weight-bold mt-2">Delivery Address</p>
          <p>
            <FontAwesomeIcon icon={deliveryAddressIcon} className="mr-1" />{" "}
            {deliveryAddress?.streetAddress}, {deliveryAddress?.city?.value},{" "}
            {deliveryAddress?.province?.value}
          </p>
        </Fragment>
      ) : null}
    </Section>
  )
}

export default ClinicInformationSection
