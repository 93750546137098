import React, { Fragment } from "react"
import { Form, Formik } from "formik"
import classNames from "classnames"

import ActionLinks from "./ActionLinks"
import FormInput from "elements/Form/FormInput"

import { initialValues, validationSchema } from "../utils/verifyEmailSchema"
import { checkIfEmailAlreadyExists } from "../../../Auth/services/authActions"

const VERIFY_PASSWORD = "verifyPassword"
const INPUT_PASSWORD = "inputPassword"

const VerifyEmail = ({ loading, setEmail, setLoading, setView }) => {
  const handleSubmit = async values => {
    setLoading(true)
    const providers = await checkIfEmailAlreadyExists({ email: values.email })
    setEmail(values.email)
    if (providers.includes("password")) setView(VERIFY_PASSWORD)
    else setView(INPUT_PASSWORD)
    setLoading(false)
  }

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <FormInput
              name="email"
              isRequired
              label="Email"
              placeholder="juan@mail.com"
            />
            <button
              className={classNames("button is-primary is-fullwidth", {
                "is-loading": loading,
              })}
              type="submit"
            >
              Next
            </button>
            <ActionLinks />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default VerifyEmail
