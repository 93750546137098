import React from "react"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faUserCircle, faListAlt } from "@fortawesome/free-solid-svg-icons"

import Section from "elements/Section"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"
// import UploadPRCDropzone from "./UploadPRCDropzone"
// import UploadGuidelines from "elements/UploadGuidelines"
import { specializationChoices } from "../utils/doctorEnrollmentSchema"

// import { AppContext } from "../../../../context/AppContext"

const PersonalDetailsSection = ({ emailAddress, values }) => {
  // const { state } = useContext(AppContext)
  //let PRC_FRONT = state?.doctorEnrollment?.prcId?.front
  //let PRC_BACK = state?.doctorEnrollment?.prcId?.back

  return (
    <Section title="Personal Information">
      <FormInput
        label="First Name"
        placeholder="Juan"
        type="text"
        name="firstName"
        isRequired
      />
      <FormInput
        label="Last Name"
        placeholder="dela Cruz"
        type="text"
        name="lastName"
        isRequired
      />

      <FormInput
        label="Middle Initial"
        placeholder="A"
        type="text"
        name="middleInitial"
        className="is-uppercase"
        maxLength={2}
      />

      <FormInput
        label="Email"
        placeholder="jdelacruz@email.com"
        type="email"
        name="email"
        isRequired
        disabled={emailAddress}
      />
      <FormInput
        label="Mobile Number"
        placeholder="09991234567"
        type="tel"
        pattern="[0,9]{2}[0-9]{9}"
        name="mobileNumber"
        maxLength={11}
        isRequired
      />
      <FormInput
        name="prcLicenseNumber"
        label="PRC License Number"
        isRequired
        placeholder="0012345"
        maxLength={7}
        isNumeric
      />
      <FormInput
        name="ptrLicenseNumber"
        label="PTR Number"
        placeholder="0012345"
        isNumeric
      />
      <FormSelect
        value={values.specialization}
        label="Specialization"
        name="specialization"
        isRequired
        options={specializationChoices}
        placeholder="Select or type specialization"
      />
      {values?.specialization?.value === "Other" && (
        <FormInput
          name="otherSpecialization"
          label="Please indicate your specialization"
          isRequired
          placeholder="Endocrinology"
        />
      )}
      {/* <p className="is-size-5 mb-1">Upload PRC ID</p>
      <UploadGuidelines />
      <div className="columns">
        <div className="column">
          <UploadPRCDropzone image={PRC_FRONT} type="FRONT">
            <FontAwesomeIcon icon={faUserCircle} className="mb-1" size="2x" />
            <p>PRC ID Front</p>
          </UploadPRCDropzone>
        </div>
        <div className="column">
          <UploadPRCDropzone image={PRC_BACK} type="BACK">
            <FontAwesomeIcon icon={faListAlt} className="mb-1" size="2x" />
            <p>PRC ID Back</p>
          </UploadPRCDropzone>
        </div>
      </div> */}
    </Section>
  )
}

export default PersonalDetailsSection
