import firebase from "firebase"
import {
  GATSBY_AWS_S3_BUCKET_NAME,
  GATSBY_FIREBASE_DOCTOR_ROLE_ID,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_ENV,
} from "gatsby-env-variables"
import { capitalize } from "../../../../services/general"
import { sendToZendesk } from "../../../../services/zendeskService"
import {
  checkIfUserAlreadyExists,
  handleSignOut,
} from "../../../Auth/services/authActions"

import { getAuthUser } from "../../../Auth/services/authUser"
import { doctorEnrollmentZendeskTemplate } from "../templates/doctorEnrollmentZendeskTemplate"
import { validationSchema } from "../utils/doctorEnrollmentSchema"
import { handleError } from "../../../../services/handleError"

export const sendEnrollmentFormToZendesk = config => {
  let { enrollmentData, ticketTemplate, type } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"

  let tags = [`${type}_enrollment`, "novo_nordisk", "ozempic"]
  if (isTest) tags.push("test")

  let subject = `${isTest ? "[TEST] " : ""}${capitalize(
    type
  )} Enrollment Form of ${enrollmentData?.firstName} ${
    enrollmentData?.lastName
  }`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${enrollmentData?.firstName} ${enrollmentData?.lastName}`,
      email: enrollmentData?.email,
    },
    comment: { body: ticketTemplate({ enrollmentData }) },
  }

  return sendToZendesk(requestBody || {}, []) // Removed file array
}

export const shouldDoctorButtonBeDisabled = async ({ formData }) =>
  !(await validationSchema.isValid({ ...formData }))

export const handleEnrollDoctor = async ({
  enrollmentData,
  errorCallback,
  callback,
}) => {
  try {
    let { addresses, userData } = getAuthUser()
    let addressesId = addresses?.id || ""
    let addressesList = addresses?.addresses || []
    if (!userData?.id)
      userData = await checkIfUserAlreadyExists({ email: enrollmentData.email })

    let authUid = userData?.authUid

    if (!authUid) {
      let createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(
          enrollmentData.email,
          enrollmentData.password
        )
      authUid = createdUser.user.uid
    }

    let userRoles =
      userData?.roles?.filter(
        role =>
          role.subdomain !== GATSBY_AWS_S3_BUCKET_NAME ||
          role.role !== GATSBY_FIREBASE_DOCTOR_ROLE_ID
      ) || []

    let healthcareProvider = userData?.healthcareProvider || []
    let doctorInformation = {
      type: "doctor",
      prcLicenseNumber: enrollmentData?.prcLicenseNumber,
      ptrLicenseNumber: enrollmentData?.ptrLicenseNumber || "",
      specialization: enrollmentData?.specialization,
      otherSpecialization: enrollmentData?.otherSpecialization,
    }
    healthcareProvider = healthcareProvider.filter(
      details => details.type !== doctorInformation.type
    )

    let hospitalName =
      enrollmentData?.primaryClinic?.name?.value === "Other"
        ? enrollmentData?.primaryClinic?.otherHospital
        : enrollmentData?.primaryClinic?.name?.value

    let addressObject = {
      type: "hospital",
      name: hospitalName,
      streetAddress: enrollmentData?.primaryClinic?.streetAddress,
      city: enrollmentData?.primaryClinic?.city?.value,
      province: enrollmentData?.primaryClinic?.province?.value,
      primary: true,
    }

    let deliveryAddressObject = {
      type: enrollmentData?.deliveryAddress?.addressType?.toLowerCase(),
      streetAddress: enrollmentData?.deliveryAddress?.streetAddress,
      city: enrollmentData?.deliveryAddress?.city?.value,
      province: enrollmentData?.deliveryAddress?.province?.value,
    }

    let finalAddressList = addressesList?.map(address => {
      if (address.type === "hospital" && address.primary)
        return {
          ...address,
          primary: false,
        }
      return { ...address }
    })
    finalAddressList = [...addressesList, { ...addressObject }]

    if (enrollmentData?.isClinicDeliveryAddress?.length < 1)
      finalAddressList.push({ ...deliveryAddressObject })

    if (!addressesId) {
      addressesId = await firebase
        .firestore()
        .collection("addresses")
        .add({ addresses: [...finalAddressList] })
      addressesId = addressesId.id
    } else {
      await firebase
        .firestore()
        .collection("addresses")
        .doc(addressesId)
        .update({ addresses: [...finalAddressList] })
    }

    let userDocument = {
      firstName: enrollmentData?.firstName,
      lastName: enrollmentData?.lastName,
      middleInitial: enrollmentData?.middleInitial || "",
      mobileNumber: enrollmentData?.mobileNumber,
      email: enrollmentData?.email,
      healthcareProvider: [...healthcareProvider, { ...doctorInformation }],
      addresses: addressesId,
      roles: [
        ...userRoles,
        {
          subdomain: GATSBY_AWS_S3_BUCKET_NAME,
          role: GATSBY_FIREBASE_DOCTOR_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
      ],
    }
    if (userData?.id)
      await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ ...userDocument, authUid })
    else
      await firebase
        .firestore()
        .collection("users")
        .add({ ...userDocument, authUid })

    await sendEnrollmentFormToZendesk({
      enrollmentData,
      ticketTemplate: doctorEnrollmentZendeskTemplate,
      type: "doctor",
      //fileArray: [enrollmentData.prcId.front, enrollmentData.prcId.back],
    })

    handleSignOut({ redirect: false })
    if (callback) callback()
  } catch (error) {
    let errorMessage =
      "There was an error sending your enrollment. Please try again."
    handleError({ error, errorCallback, errorMessage })
  }
}
