import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import VerifyEmail from "./DoctorSignUp/VerifyEmail"
import InputPassword from "./DoctorSignUp/InputPassword"
import VerifyPassword from "./DoctorSignUp/VerifyPassword"

import { getAuthUser } from "../../Auth/services/authUser"

const VERIFY_EMAIL = "verifyEmail"
const VERIFY_PASSWORD = "verifyPassword"
const INPUT_PASSWORD = "inputPassword"

const DoctorSignUp = () => {
  return (
    <Layout
      title="Register as Partner Doctor"
      subtitle="Fill out your details."
      seoTitle="Register as Partner Doctor"
    >
      <Container isCentered fullhd={4}>
        <DoctorSignUpView />
      </Container>
    </Layout>
  )
}

const DoctorSignUpView = () => {
  const [view, setView] = useState(VERIFY_EMAIL)
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (getAuthUser()?.userData?.email) navigate("/doctor/enroll")
  }, [])

  switch (view) {
    case VERIFY_EMAIL:
      return (
        <VerifyEmail
          loading={loading}
          setEmail={setEmail}
          setLoading={setLoading}
          setView={setView}
        />
      )
    case VERIFY_PASSWORD:
      return (
        <VerifyPassword
          loading={loading}
          email={email}
          setLoading={setLoading}
        />
      )
    case INPUT_PASSWORD:
      return (
        <InputPassword
          loading={loading}
          email={email}
          setLoading={setLoading}
        />
      )
    default:
      return null
  }
}

export default DoctorSignUp
