import * as Yup from "yup"
import {
  INVALID_EMAIL,
  REQUIRED_MESSAGE,
} from "../../../../services/validations"

export const initialValues = email => ({ email: email, password: "" })

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(INVALID_EMAIL)
    .required(REQUIRED_MESSAGE),
  password: Yup.string().required(REQUIRED_MESSAGE),
})
