import { useStaticQuery, graphql } from "gatsby"

const pdfFile = graphql`
  {
    file: file(relativePath: { eq: "Ozempic-HCP-Starter-Kit-v5.pdf" }) {
      publicURL
      name
    }
  }
`

export const useBriefingGuide = () => {
  const data = useStaticQuery(pdfFile)
  return data.file.publicURL
}
