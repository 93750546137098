import React, { useContext, useState, useEffect } from "react"
import { navigate } from "gatsby"

import FormCheckbox from "elements/Form/FormCheckbox"

import Layout from "layout/Layout"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"
import PersonalInformationSection from "./DoctorEnrollmentSummary/PersonalInformationSection"

import { AppContext } from "../../../context/AppContext"
import { doctorEnrollment, toast } from "../../../context/AppReducer"
import { handleEnrollDoctor } from "./services/doctorEnrollment"
import doctorConsentAndAuth from "./utils/doctorConsentAndAuth.json"
import ClinicInformationSection from "./DoctorEnrollmentSummary/ClinicInformationSection"
import CommunicationPreferencesSummary from "./DoctorEnrollmentSummary/CommunicationPreferenceSummary"
import { shouldDoctorButtonBeDisabled } from "./services/doctorEnrollment"

const DoctorEnrollmentSummary = () => {
  const [loading, setLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const { state, dispatch } = useContext(AppContext)
  const enrollmentData = state.doctorEnrollment

  useEffect(() => {
    const checkIfDisabled = async () => {
      let isFormValid = await shouldDoctorButtonBeDisabled({
        formData: enrollmentData,
      })
      setIsDisabled(isFormValid)
    }
    checkIfDisabled()
  }, [enrollmentData])

  const handleSubmitError = ({ message }) => {
    setLoading(false)
    dispatch({
      type: toast.SHOW_TOAST,
      payload: {
        message: message,
        color: "danger",
      },
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    handleEnrollDoctor({
      enrollmentData,
      errorCallback: handleSubmitError,
      callback: () => {
        dispatch({
          type: doctorEnrollment.RESET_DOCTOR_ENROLLMENT,
        })
        setLoading(false)
        navigate("/doctor/enroll/completed")
      },
    })
  }

  const consentToBeEngaged = (
    <span>
      I consent to be engaged by Novo Nordisk Pharmaceuticals (Philippines),
      Inc. and its service providers via digital channels and mobile phone for
      updates on our CMEs, promotions, and materials related to our products,
      patient support, and the Early Experience Program.
    </span>
  )

  return (
    <Layout
      title="Enrollment Summary"
      subtitle={
        <span>
          Please review if the following information is correct.
          <br />
          Your information will be used as a basis for your enrollment and will
          be reflected in your ePrescriptions.
        </span>
      }
      seoTitle="Enrollment Summary"
    >
      <Container isCentered desktop={6} fullhd={6}>
        <PersonalInformationSection enrollmentData={enrollmentData} />
        <CommunicationPreferencesSummary values={enrollmentData} />
        <ClinicInformationSection enrollmentData={enrollmentData} />
        <ConsentAndAuthorization
          termsAndConditionsData={doctorConsentAndAuth}
          handleOnSubmit={handleSubmit}
        >
          <FormCheckbox
            name="consentToBeEngaged"
            values={enrollmentData.consentToBeEngaged}
            options={[consentToBeEngaged]}
            onChange={event =>
              dispatch({
                type: doctorEnrollment.SAVE_DOCTOR_ENROLLMENT,
                payload: {
                  consentToBeEngaged: event.target.checked
                    ? [consentToBeEngaged]
                    : [],
                },
              })
            }
          />
          <ActionButtons
            back={{ label: "Back", link: "/doctor/enroll" }}
            submit={{
              label: "Submit",
              loading,
              disabled: isDisabled,
            }}
          />
        </ConsentAndAuthorization>
      </Container>
    </Layout>
  )
}

export default DoctorEnrollmentSummary
