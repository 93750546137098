import React, { useContext, useEffect } from "react"
import { Form, Formik } from "formik"
import { navigate } from "gatsby"

import Layout from "layout/Layout"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import PersonalDetailsSection from "./DoctorEnrollment/PersonalDetailsSection"
import DeliveryDetailsSection from "./DoctorEnrollment/DeliveryDetailsSection"
import ClinicInformationSection from "./DoctorEnrollment/ClinicInformationSection"
import CommunicationPreferences from "./DoctorEnrollment/CommunicationPreferences"

import { AppContext } from "../../../context/AppContext"
import { getAuthUser } from "../../Auth/services/authUser"
import { doctorEnrollment } from "../../../context/AppReducer"
import { validationSchema } from "./utils/doctorEnrollmentSchema"

const DoctorEnrollment = () => {
  const { state, dispatch } = useContext(AppContext)
  const emailAddress =
    getAuthUser()?.userData?.email || state?.doctorEnrollment?.email

  useEffect(() => {
    dispatch({
      type: doctorEnrollment.SAVE_DOCTOR_ENROLLMENT,
      payload: { email: emailAddress || "" },
    })
  }, [dispatch, emailAddress])

  const handleSubmit = values => {
    dispatch({
      type: doctorEnrollment.SAVE_DOCTOR_ENROLLMENT,
      payload: { ...values, prcId: state.doctorEnrollment.prcId },
    })
    navigate("/doctor/enroll/summary")
  }

  return (
    <Layout
      title="Enroll as Doctor"
      subtitle={
        <span>
          Please answer the following questions accurately.
          <br />
          Your information will be used as a basis for your enrollment and will
          be reflected in your ePrescriptions.
        </span>
      }
      seoTitle="Enroll as Doctor"
    >
      <Container isCentered desktop={6} fullhd={6}>
        <Formik
          initialValues={{
            ...state.doctorEnrollment,
            email: emailAddress || "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <PersonalDetailsSection
                emailAddress={emailAddress}
                values={values}
              />
              <ClinicInformationSection
                values={values}
                primaryClinic={values.primaryClinic}
                setFieldValue={setFieldValue}
              />
              <DeliveryDetailsSection
                values={values}
                setFieldValue={setFieldValue}
              />
              <CommunicationPreferences values={values} />
              <ActionButtons submit={{ label: "Next" }} />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default DoctorEnrollment
