import React from "react"
import Img from "gatsby-image"

const HomeMediaContainer = ({ description, image }) => (
  <div className="column mx-1 mb-3-mobile">
    <div className="is-flex is-justify-content-center">
      <Img fixed={image} />
    </div>
    <p
      className="mx-3-mobile is-size-5 has-text-centered"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  </div>
)

export default HomeMediaContainer
