import React from "react"
import { Link } from "gatsby"
import { GATSBY_AWS_S3_BUCKET_NAME } from "gatsby-env-variables"

import classNames from "classnames"

import { Brand } from "elements/Brand"
import styles from "../Layout/layout.module.scss"
// import { useStaticQuery, graphql, Link } from "gatsby"

const LearnMore = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     stethoscopeTeal: file(relativePath: { eq: "stethoscope.jpg" }) {
  //       childImageSharp {
  //         fluid(
  //           maxWidth: 1000
  //           duotone: { highlight: "#ffffff", shadow: "#6ea9a9" }
  //         ) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     stethoscopeOrange: file(relativePath: { eq: "stethoscope.jpg" }) {
  //       childImageSharp {
  //         fluid(
  //           maxWidth: 1000
  //           duotone: { highlight: "#ffffff", shadow: "#f6ad7a" }
  //         ) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  // const stethoscopeTeal = data.stethoscopeTeal.childImageSharp.fluid
  // const stethoscopeOrange = data.stethoscopeOrange.childImageSharp.fluid

  return (
    <div id="learn-more" className="pt-2">
      <h3 className="is-size-3 my-3 has-text-centered">
        You can nominate your patients in two different ways:
      </h3>
      <div className="learn-more-wrapper">
        <div className={classNames("", styles["learnMore__dividerContainer"])}>
          <div
            className={classNames("py-1 px-1", styles["learnMore__dividerOr"])}
          >
            OR
          </div>
          <div className="columns is-desktop mx-0">
            <div
              className={classNames(
                "column",
                styles["learnMore__learnMoreColumn"]
              )}
              style={{
                // backgroundImage: `url(${stethoscopeOrange.src})`,
                // backgroundPosition: "cover",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "cover",
                backgroundColor: "#fae3d3",
              }}
            >
              <div className="px-2-mobile px-4 is-flex is-flex-direction-column has-text-centered is-justify-content-center ">
                <h3>Share the link</h3>
                <p className="pb-2">
                  Ask your patients to fill out the enrollment form and upload
                  their <Brand /> prescription at{" "}
                </p>
                <a
                  className={classNames(
                    "button has-background-white static has-text-weight-bold py-1",
                    styles["url"]
                  )}
                  href="/patient"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <span className="has-text-secondary">
                    {GATSBY_AWS_S3_BUCKET_NAME}
                  </span>
                  /patient
                </a>
              </div>
            </div>
            <div
              className={classNames(
                "column",
                styles["learnMore__learnMoreColumn"]
              )}
              style={{
                // backgroundImage: `url(${stethoscopeTeal.src})`,
                // backgroundPosition: "cover",
                // backgroundRepeat: "no-repeat",
                // backgroundSize: "cover",
                backgroundColor: "#cfe1e1",
              }}
            >
              <div className="px-2-mobile px-4 is-flex is-flex-direction-column has-text-centered is-justify-content-center">
                <h3>Issue an ePrescription</h3>
                <p className="pb-2">
                  Use MedGrocer’s platform to issue a <Brand /> prescription and
                  automatically forward it to your patient via SMS.{" "}
                </p>
                <Link
                  className={classNames(
                    "button is-primary is-medium",
                    styles["home__buttonIsFullwidth"]
                  )}
                  to="/issue-erx/patient-details"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearnMore
