import React, { Fragment } from "react"
import Section from "elements/Section"

const CommunicationPreferencesSummary = ({ values }) => {
  return (
    <Section title="Communication Preferences">
        <Fragment>
          <p className="mt-1 mb-1-mobile">
            I want to be contacted through:{" "}
            <span className="has-text-weight-bold">
              <br className="is-hidden-desktop is-hidden-tablet" />
              {values?.contactThrough.length > 0 ? values?.contactThrough.join(", ") : "N/A"}
            </span>
          </p>
        </Fragment>
      </Section>
  )
}

export default CommunicationPreferencesSummary
