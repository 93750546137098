import React, { Fragment, useContext } from "react"
import { Form, Formik } from "formik"
import classNames from "classnames"
import { navigate } from "gatsby"

import ActionLinks from "./ActionLinks"
import FormInput from "elements/Form/FormInput"

import { AppContext } from "../../../../context/AppContext"
import { initialValues, validationSchema } from "../utils/inputPasswordSchema"
import { doctorEnrollment } from "../../../../context/AppReducer"

const NEW_DOCTOR = "new"

const InputPassword = ({ loading, email, setLoading }) => {
  const { dispatch, state } = useContext(AppContext)

  const handleNewEmailSignUp = async ({ values }) => {
    await dispatch({
      type: doctorEnrollment.SAVE_DOCTOR_ENROLLMENT,
      payload: {
        ...state.doctorEnrollment,
        ...values,
        status: NEW_DOCTOR,
      },
    })
    navigate("/doctor/enroll")
  }

  const handleSubmit = values => {
    setLoading(true)
    handleNewEmailSignUp({ values })
  }

  return (
    <Fragment>
      <Formik
        initialValues={initialValues(email)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            {/* disable field only if the email it initially received is the one in its form state. otherwise, let user type in email */}
            <FormInput
              name="email"
              isRequired
              label="Email"
              placeholder="juan@mail.com"
              disabled={email === values.email}
            />
            <FormInput
              name="password"
              isRequired
              label="Password"
              placeholder="********"
              type="password"
            />
            <FormInput
              name="confirmPassword"
              isRequired
              label="Confirm Password"
              placeholder="********"
              type="password"
            />
            <button
              className={classNames("button is-primary is-fullwidth", {
                "is-loading": loading,
              })}
              type="submit"
            >
              Next
            </button>
            <ActionLinks />
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default InputPassword
