import React from "react"
import { Router } from "@reach/router"
import DoctorEnrollment from "components/Enrollment/Doctor/DoctorEnrollment"
import DoctorEnrollmentSummary from "components/Enrollment/Doctor/DoctorEnrollmentSummary"
import DoctorEnrollmentCompleted from "components/Enrollment/Doctor/DoctorEnrollmentCompleted"
import DoctorSignUp from "enrollment/Doctor/DoctorSignUp"
import Doctor from "../components/StaticPages/Doctor"

export default () => {
  return (
    <Router basepath="/doctor">
      <DoctorEnrollmentSummary path="/enroll/summary" />
      <DoctorEnrollmentCompleted path="/enroll/completed" />
      <DoctorEnrollment path="/enroll" />
      <DoctorSignUp path="/sign-up" />
      <Doctor path="/"/>
    </Router>
  )
}
