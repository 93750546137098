import React, { Fragment } from "react"
import { Link } from "gatsby"

const ActionLinks = () => (
  <Fragment>
    <p className="help has-text-centered pt-1">
      By signing up, you agree to our{" "}
      <Link to="/terms-and-conditions">Terms</Link> and
      <Link to="/privacy-policy"> Privacy Policy</Link>.
    </p>
    <section>
      <p className="has-text-centered is-size-6 pt-2">
        Already have an account? <Link to="/sign-in">Sign In</Link>
      </p>
    </section>
  </Fragment>
)

export default ActionLinks
