import React from "react"
import FormCheckbox from "elements/Form/FormCheckbox"
import Section from "elements/Section"
import Message from "elements/Message"
import { contactThroughChoices } from "../utils/doctorEnrollmentSchema"

const CommunicationPreferences = ({ values }) => {
  return (
    <Section title="Communication Preferences" id="communication-preferences">
      <Message color="light">
        Our team will contact you for updates on your enrollment status and
        deliveries. Throughout the Early Experience Program, we may send you
        communications around product resources, educational events, and the
        status of your patients enrolled in the program.
      </Message>
      <FormCheckbox
        title="I want to be contacted through: "
        name="contactThrough"
        helper="Please choose one or more channels. Our team will contact you via SMS by default."
        values={values.contactThrough}
        options={contactThroughChoices}
        disabledOptions={["SMS"]}
        isRequired
      />
    </Section>
  )
}

export default CommunicationPreferences
