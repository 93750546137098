import React from "react"
import { Link } from "gatsby"
import { GATSBY_AWS_SES_EMAIL_TO } from "gatsby-env-variables"
import { useBriefingGuide } from "./hooks/useBriefingGuide"

import Completed from "../../Completed"
import { Brand, ProgramName } from "../../Elements/Brand"
import DeliveryDisclaimerMessage from "../../Elements/DeliveryDisclaimerMessage"

const DoctorEnrollmentCompleted = () => {
  const pdfLink = useBriefingGuide()

  return (
    <Completed title="Thank you!" seoTitle="Submission Completed">
      <p>
        Thank you for enrolling in the{" "}
        <ProgramName className="has-text-weight-bold has-text-primary" />.
      </p>
      <p>
        Your enrollment is currently being processed and your account will be
        ready after one working day. You will receive an SMS once your account
        has been activated.
      </p>
      <p>
        Once your account is activated, you may nominate patients and issue{" "}
        <Brand /> ePrescriptions through the website. You may nominate up to
        five (5) patients to receive the samples. <DeliveryDisclaimerMessage />
      </p>
      <p>
        You can download the digital welcome kit{" "}
        <a target="_blank" rel="noreferrer noopener" href={pdfLink}>
          here
        </a>{" "}
        to learn more about <Brand /> and the program mechanics. If you have
        opted for email and SMS communication, the link to the welcome kit will
        be provided there as well.
      </p>
      <p>
        Feel free to reach us at{" "}
        <a
          href={`mailto:${GATSBY_AWS_SES_EMAIL_TO}`}
          className="has-text-weight-bold"
        >
          {GATSBY_AWS_SES_EMAIL_TO}
        </a>{" "}
        or 0968 230 6545 for any questions.
      </p>
      <p className="has-text-centered mt-4">
        <Link to="/" className="button is-primary is-medium">
          Back to Home Page
        </Link>
      </p>
    </Completed>
  )
}

export default DoctorEnrollmentCompleted
